import React from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./Kuota.service";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { TooltipHoverModeConfig, Tooltipped } from "react-md";

const _baseId = "satuan-table";

const KuotaTable = ({ className = "", showCommandbar = true, modalStore }) => {
  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title={t.translate("modules.quota.title")}
        className={className}
        showActionColumn={false}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultCollapse={true}
        // useCriteria={false}
        selectable={false}
        useQueryHistory={false}
        // extraFilter={<div>Test</div>}
        multiColumnFilter={true}
        // extraFilter={<div></div>}
        columns={[
          {
            label: "Produk",
            key: "produkNama",
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: (item) => (item.produk ? item.produk.nama : ""),
          },
          {
            label: "Reference No",
            key: "referenceNo",
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.referenceNo,
          },
          {
            label: "Order No",
            key: "orderNo",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => (item.order ? item.order.orderNo : ""),
          },
          {
            label: "Deskripsi",
            key: "deskripsi",
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: (item) => (item.deskripsi ? item.deskripsi : "-"),
          },
          {
            label: "Method",
            key: "apiMethod",
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: (item) => (item.api ? item.api.method : "-"),
          },
          {
            label: "Path",
            key: "apiPath",
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: (item) =>
              item.api ? (
                <div>
                  <TooltipHoverModeConfig
                    enabled={true}
                    delayTimeout={2}
                    defaultDelay={2}
                  >
                    <Tooltipped
                      tooltip={
                        <span>
                          {item.api.description ? item.api.description : ""}
                        </span>
                      }
                      defaultPosition="below"
                    >
                      <a>
                        <span>{item.api.path}</span>
                      </a>
                    </Tooltipped>
                  </TooltipHoverModeConfig>
                </div>
              ) : (
                "-"
              ),
          },
          {
            label: "Tipe Transaksi",
            key: "tipeTransaksi",
            sortable: true,
            definition: {
              inputType: TableWrapper.inputTypes.INPUT,
              defaultValue: "",
            },
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.tipeTransaksi,
          },
          {
            label: "Unit",
            key: "unit",
            sortable: true,
            type: TableWrapper.dataTypes.NUMBER,
            render: (item) => item.unit,
          },
          {
            label: "Open Balance",
            key: "openBalance",
            sortable: true,
            type: TableWrapper.dataTypes.NUMBER,
            render: (item) => item.openBalance,
          },
          {
            label: "Close Balance",
            key: "closeBalance",
            sortable: true,
            type: TableWrapper.dataTypes.NUMBER,
            render: (item) => item.closeBalance,
          },
          {
            label: "Tanggal Dibuat",
            key: "createdDate",
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            definition: {
              inputType: TableWrapper.inputTypes.DATEPICKER,
              defaultValue: "",
              criterias: ["greaterThan", "lessThan"],
            },
            render: (item) => item.createdDate,
          },
          {
            label: "Dibuat Oleh",
            key: "createdBy",
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.createdBy,
          },
        ]}
        actions={[]}
        itemActions={[]}
        onFetchData={async (query) => {
          const key = Object.keys(query).find((key) =>
            key.startsWith("createdDate")
          );

          if (key) {
            const queryType = key?.split(".")[1];

            const date =
              queryType === "greaterThan"
                ? moment(query[key]).add({ hours: 23, minutes: 59 })
                : moment(query[key]).subtract({ minutes: 1 });

            const iso = date.toISOString();

            query[key] = iso;
          }

          query.sort = "createdDate,DESC";
          return service.get(query);
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      {/* <SatuanForm
        visible={showForm}
        onRequestClose={(data) => {
          TableWrapper.reload(_baseId)
          setShowForm(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
      /> */}
    </>
  );
};

export default inject("modalStore")(observer(KuotaTable));
