import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import service from "./AksesProduk.service";
import t from "counterpart";

const defaultData = () => ({});

const AksesProdukForm = ({
  visible = false,
  onRequestClose = () => {},
  produkId,
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  // useEffect(() => {
  // 	if (visible) {
  // 		let selectedItem = defaultData();
  // 		if (props.item) {
  // 			selectedItem = cloneDeep(props.item)
  // 			selectedItem.number = String(selectedItem.number)
  // 		}
  // 		setItem(selectedItem)
  // 	} else {
  // 		setItem(defaultData())
  // 	}
  // 	setReady(visible)
  // }, [visible])

  return (
    ready && (
      <DataForm
        baseId="akses-produk-form"
        title="HAK AKSES"
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={props.item || defaultData()}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Username",
            key: "username",
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: "Email",
            key: "email",
            validation: "required",
          },
        ]}
        onBeforeChange={(key, value) => {
          return value;
        }}
        onSubmit={async (data, callback) => {
          var res = {};
          try {
            if (!data.id) {
              res = await service.http.post(
                `/produk/${produkId}/akses_produk`,
                data
              );
            } else {
              res = await service.http.put(
                `/produk/${produkId}/akses_produk`,
                data
              );
            }
            callback(t.translate("sentence.dataTelahDiSimpan"), false);
          } catch (e) {
            callback(e, true, false);
          }
        }}
      />
    )
  );
};

export default AksesProdukForm;
