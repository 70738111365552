import { crud } from "../../libs/react-mpk/services";
import http from "../../libs/react-mpk/services/http.service";

let service = new crud("/api");

service.http = http;
service.put = (data) => {
  let config = service.clearParams();
  return http.request({
    method: http.methods.PUT,
    url: `${service.path}`,
    data,
    options: service.options,
    config,
  });
};

service.getOne = (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${service.path}/${id}`,
    options: service.options,
    config: service.config,
  });
};

service.delete = (produkId, id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `/produk/${produkId}${service.path}/${id}`,
    options: service.options,
    config: service.config,
  });
};

export default service;
