import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import service from "./ApiProduk.service";
import t from "counterpart";

const defaultData = () => ({});

const ApiProdukForm = ({
  visible = false,
  onRequestClose = () => {},
  produkId,
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [methodList, setMethodList] = useState([
    { value: "GET", label: "GET" },
    { value: "POST", label: "POST" },
    { value: "PUT", label: "PUT" },
  ]);

  return (
    ready && (
      <DataForm
        baseId="api-produk-form"
        title="API"
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={props.item || defaultData()}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("modules.produk.form.api.code"),
            key: "code",
            validation: "required",
            minLength: 5,
            maxLength: 10,
          },
          {
            inputType: DataForm.inputTypes.SELECT,
            label: t.translate("modules.produk.form.api.method"),
            key: "method",
            options: methodList,
            validation: "required",
            style: { paddingBottom: 10 },
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("modules.produk.form.api.path"),
            key: "path",
            validation: "required",
          },
          {
            inputType: DataForm.inputTypes.TEXTAREA,
            label: t.translate("modules.produk.form.api.description"),
            key: "description",
            validation: "required",
          },
        ]}
        onBeforeChange={(key, value) => {
          return value;
        }}
        onSubmit={async (data, callback) => {
          var res = {};
          try {
            if (!data.id) {
              res = await service.http.post(`/produk/${produkId}/api`, data);
            } else {
              res = await service.http.put(
                `/produk/${produkId}/api/${data.id}`,
                data
              );
            }
            callback(t.translate("sentence.dataTelahDiSimpan"), false);
          } catch (e) {
            callback(e, true, false);
          }
        }}
      />
    )
  );
};

export default ApiProdukForm;
