import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./ApiProduk.service";
import moment from "moment";
import ApiProdukDialog from "./ApiProduk.dialog";
import { inject, observer } from "mobx-react";
import { TooltipHoverModeConfig, Tooltipped } from "react-md";

const _baseId = "api-produk-table";

const ApiProdukTable = ({
  className = "",
  showCommandbar = false,
  modalStore,
  produkId,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        className={className}
        hintIconClassName="mdi mdi-information"
        defaultData={[]}
        defaultSortBy="createdDate"
        selectable={false}
        useQueryHistory={false}
        columns={[
          {
            label: t.translate("modules.produk.form.api.code"),
            key: "code",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.code,
          },
          {
            label: t.translate("modules.produk.form.api.method"),
            key: "method",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.method,
          },
          {
            label: t.translate("modules.produk.form.api.path"),
            key: "path",
            type: TableWrapper.dataTypes.STRING,
            // render: (item) => item.path,
            render: (item) =>
              item.path ? (
                <div>
                  <TooltipHoverModeConfig
                    enabled={true}
                    delayTimeout={2}
                    defaultDelay={2}
                  >
                    <Tooltipped
                      tooltip={
                        <span>{item.description ? item.description : ""}</span>
                      }
                      defaultPosition="below"
                    >
                      <a>
                        <span>{item.path}</span>
                      </a>
                    </Tooltipped>
                  </TooltipHoverModeConfig>
                </div>
              ) : (
                "-"
              ),
          },
          // {
          //   label: t.translate("modules.produk.form.api.description"),
          //   key: "description",
          //   type: TableWrapper.dataTypes.STRING,
          //   render: (item) => item.description,
          // },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setSelectedItem(null);
              setShowForm(true);
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setShowForm(true);
            },
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              modalStore.showConfirm({
                title: t.translate("sentence.deleteDialogTitle"),
                children: t.translate("sentence.deleteDialogMessege"),
                onSubmit: async (callback) => {
                  try {
                    await service.delete(produkId, item.id);
                    callback(t.translate("sentence.deleteDialogSuccess"));
                    TableWrapper.reload(_baseId);
                  } catch (error) {
                    console.log(error, "error");
                    callback(error.message, true, true);
                  }
                },
              });
            },
            true
          ),
        ]}
        onFetchData={async (query) => {
          return service.http.get(`/produk/${produkId}/api`, query);
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <ApiProdukDialog
        visible={showForm}
        produkId={produkId}
        onRequestClose={(data) => {
          TableWrapper.reload(_baseId);
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />
    </>
  );
};

export default inject("modalStore")(observer(ApiProdukTable));
