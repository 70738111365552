import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./AksesProduk.service";
import moment from "moment";
import AksesProdukDialog from "./AksesProduk.dialog";
import { inject, observer } from "mobx-react";

const _baseId = "satuan-table";

const AksesProdukTable = ({
  className = "",
  showCommandbar = false,
  modalStore,
  produkId,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        // title={t.translate('modules.satuan.title')}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        // hintMore={t.translate('modules.table.hint.more')}
        defaultData={[]}
        defaultSortBy="createdDate"
        selectable={false}
        useQueryHistory={false}
        columns={[
          {
            label: "Username",
            key: "username",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.username,
          },
          {
            label: "Email",
            key: "email",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.email,
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setSelectedItem(null);
              setShowForm(true);
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item);
              setShowForm(true);
            },
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              modalStore.showConfirm({
                title: t.translate("sentence.deleteDialogTitle"),
                children: t.translate("sentence.deleteDialogMessege"),
                onSubmit: async (callback) => {
                  await service.delete(item.id);
                  callback(t.translate("sentence.deleteDialogSuccess"));
                  TableWrapper.reload(_baseId);
                },
              });
            },
            true
          ),
        ]}
        onFetchData={async (query) => {
          return service.http.get(`/produk/${produkId}/akses_produk`, query);
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <AksesProdukDialog
        visible={showForm}
        produkId={produkId}
        onRequestClose={(data) => {
          TableWrapper.reload(_baseId);
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
      />
    </>
  );
};

export default inject("modalStore")(observer(AksesProdukTable));
